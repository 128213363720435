import axios from 'axios'
import settings from '../settings/apis.js'
import {store} from '../store/index.js'

class BoTicketsApi {
    constructor() {
        this.Api = axios.create({
            baseURL: settings[process.env.NODE_ENV].boApiUrl + "/tickets/",
            withCredentials: false,

            headers: {
                'Content-Type': 'application/json',
                'X-Access-Token': store.getters['user/accessToken']
            }
        });
    }
    getTicketContent(ticketSerial) {
        return this.Api.get('getTicket/' + ticketSerial)
    }
    getTickets(params) {
        return this.Api.get('getTickets', {params});
    }
    ticketAction(actionDetails) {
        return this.Api.post('submitAction', actionDetails)
    }

}

export {
    BoTicketsApi
}
